import Formsy from 'formsy-react';
import { navigate } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ButtonLoader from '../components/buttonLoader';
//import { APPLLOCLIENT, STRIPE_CONFIG } from '../components/constant';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import ErrrorHandel from '../components/ErrrorHandel';
import Layout from '../components/layout';
import MyInput from '../components/MyInput';
import MyInputMask from '../components/MyInputMask';
import SEO from '../components/seo';
import Wizard from '../components/wizard';
import paymentIcon from '../images/gray/5.svg';
import * as actionCreators from './../store/actions';
import { restrictRouteForStep } from '../core/util';
import bottomLine from "../images/line.png"

class SecurePaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // stripe: null,
            canSubmit: false,
            gatewayerror : null,
            skiPayOpt:false
        };
    }

    // componentDidMount = async () => {
    //     if (window.Stripe) {
    //         this.setState({ stripe: window.Stripe(STRIPE_CONFIG.apikey) });
    //     } else {
    //         document.querySelector('#stripe-js').addEventListener('load', () => {
    //             this.setState({ stripe: window.Stripe(STRIPE_CONFIG.apikey) });
    //         });
    //     }
    // }
    async componentDidMount() {
        this.props.showLoader();
        if (this.props.step2response && !this.props.step2response.recordId) {
            let paramId = restrictRouteForStep(this.props.location);
            if (paramId) {
                await this.getFromDirect(paramId);
            }
        }
        this.props.hideLoader();
        if(global.dealertoken && global.dealertoken==process.env.peak_dealer){
            if (isAuthenticated()) {
                let usrdata = getAuthenticatedUser();
                if(usrdata.dealerId || usrdata.dealAdmin){
                    this.setState({ skiPayOpt: true });
                }
            }
            
        }
    }


    getFromDirect = async(paramId) => {
        const { saveSecondStep,showLoader, hideLoader, saveFirstStep, saveThirdStep, saveFourthStep, saveSecondStepResponse, saveThirdStepResponse } = this.props;
        showLoader();
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanById'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
            })
          })
        .then(res => res.json())
        .then((res) => {
            if(res.status == 1){
                let secondstepResponse = {
                    userId: res.data.step3.userId,
                    vehicleId: res.data.step3.vehicleId,
                    recordId: res.data.step3.recordId
                }
                saveFirstStep(res.data.step1);
                saveSecondStep(res.data.step2);
                saveSecondStepResponse(secondstepResponse);
                saveThirdStep(res.data.step3);
                saveThirdStepResponse(res.data.step3);
                saveFourthStep(res.data.step4)
                hideLoader();
            } else {
                navigate("/vehicleInfo/?q="+paramId+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
            }
        });
        
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    

    submit = async(model) => {
        const { showButtonLoader,showLoader, hideLoader, hideButtonLoader, step2response } = this.props;
        showLoader();
        showButtonLoader();
        
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/checkcard'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                recordId:step2response.recordId,
                userId:step2response.userId,
                cardFirstName: model.cardFirstName,
                cardLastName: model.cardLastName,
                cardNo: model.cardNo,
                cvv:model.cvv,
                expMMYY: model.expMMYY,
            })
          })
        .then(res => res.json())
        .then((res) => {
            if(res.status === 0){
                    let errorCode='';
                    errorCode = res.type;
                    if(errorCode === 'cardNo'){
                        this.refs[`createAccountForm`].updateInputsWithError({
                            [`${errorCode}`]: res.msg,
                        });
                        this.setState({ gatewayerror: null })
                    } else {
                        this.setState({ gatewayerror: res.msg })
                    }
                    
                    hideButtonLoader();
                    hideLoader();
                    this.enableButton();
            } else {
                //console.log(res);
                if(global.dealertoken && global.href) this.ahrefElement.click();
                navigate(`/confirm/?q=${step2response.recordId}`+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
            }
        });

        
    }
    startNewQoute = () => {
        this.props.clearAllSteps();
        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
    }

    
    skiPay = async() => {
        const { showButtonLoader,showLoader, hideLoader, hideButtonLoader, step2response } = this.props;
        showLoader();
        showButtonLoader();
        
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/skiPay'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                recordId:step2response.recordId,
                userId:step2response.userId
            })
          })
        .then(res => res.json())
        .then((res) => {
            if(global.dealertoken && global.href) this.ahrefElement.click();
            navigate(`/confirm/?q=${step2response.recordId}`+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
        });
        
    }

    render() {
        const { canSubmit,gatewayerror,skiPayOpt } = this.state;
        const { buttonLoader, step5, step3Data, step2Data  } = this.props;
        
        return (
            <Layout layoutType="main">
                <SEO title="Servicecontract.com - Secure Payment" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {global.dealertoken?
                <a className="hideme" id="price-step6" href={global.href+"#price-step6"} ref={ahref => this.ahrefElement = ahref} target="_top">trigger</a>
                :''}
                <div className="chat-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                        <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                            </g>
                        </g>
                    </svg>

                </div>
                <section className={global.dealertoken?'grayish':''}>
                    <Wizard></Wizard>
                    <div className="custom-container vehinfo">
                        <div className="title-container">
                            <div className="icon-image">
                                <img src={paymentIcon} alt="icon" />
                            </div>
                            <div className="title-text">
                                <h1>Secure Payment Information</h1>
                                <p>Simply provide your payment information and select the terms that best fit your needs.</p>
                            </div>
                        </div>

                        <div className="body-content inline">
                            <div className="left-content">
                                <div className="amount-container mb-16">
                                    <div className="amount-inner">
                                        <h3>Quote</h3>
                                        <div className="amt">
                                            <p>{step3Data.termMonth?'Payment':'Monthly Payment'}</p>
                                            <h2><sup>$</sup>{step3Data && step3Data.monthlyPayment ? step3Data.monthlyPayment : 0}</h2>
                                        </div>
                                        <div className="amt">
                                            {step3Data.termMonth?
                                            <><p>Months</p><h2>{step3Data.termMonth}</h2></>
                                            :
                                            <><p>Deductible</p><h2><sup>$</sup>{step3Data.payAtClaimTime}</h2></>
                                            }
                                        </div>
                                        <div className="amt">
                                            <p>Plan Level</p>
                                            <label className="text">{step3Data.levelOfCoverage}</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <Link to="/vehicleInfo" className="btn outline">START A NEW QUOTE</Link> */}
                                <button type="button" onClick={() => this.startNewQoute()}  className="btn outline quote-btn-2">START A NEW QUOTE</button>

                            </div>
                            <div className="right-content">
                                <h3 className="mb-48 mt-24 ctitle">Enter Your Credit Card Details</h3>
                                <Formsy ref="createAccountForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                    <ErrrorHandel formName={'createAccountForm'} self={this} />
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group error">
                                                <MyInput
                                                    name="cardFirstName"
                                                    type="text"
                                                    id="cardFirstName"
                                                    placeholder="First Name"
                                                    value={step5.cardFirstName ? step5.cardFirstName : step2Data.firstName}
                                                    isLabelRequired={true}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-right">
                                            <div className="form-label-group error">
                                                <MyInput
                                                    name="cardLastName"
                                                    type="text"
                                                    id="cardLastName"
                                                    placeholder="Last Name"
                                                    value={step5.cardLastName ? step5.cardLastName : step2Data.lastName}
                                                    isLabelRequired={true}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group error">
                                                <MyInputMask
                                                    name="cardNo"
                                                    id="cardNo"
                                                    mask="9999 9999 9999 9999"
                                                    placeholder="Credit Card Number"
                                                    validations={{
                                                        maxLength: 19,
                                                        minLength: 16
                                                    }}
                                                    validationError="Please enter a valid card number."
                                                    value={step5.cardNo ? step5.cardNo : ''}
                                                    isLabelRequired= {true}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    
                                    
                                        <div className="form-right">
                                            <div className="form-label-group error">
                                                <MyInputMask
                                                    name="expMMYY"
                                                    id="expMMYY"
                                                    mask="99/99"
                                                    placeholder="Exp Date (MM/YY)"
                                                    validationError="Please enter a valid MM/YY."
                                                    value={step5.expMMYY ? step5.expMMYY : ''}
                                                    isLabelRequired= {true}
                                                    className="form-control"
                                                    validations={{
                                                        minLength: 5
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group error">
                                                <MyInputMask
                                                    name="cvv"
                                                    id="cvv"
                                                    mask="999"
                                                    placeholder="CVV"
                                                    validations={{
                                                        maxLength: 3,
                                                        minLength: 3
                                                    }}
                                                    validationError="Please enter a valid CVV."
                                                    value={step5.cvv ? step5.cvv : ''}
                                                    isLabelRequired= {true}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="error-txt">{gatewayerror}</div>
                                    <div className="btn-container">
                                        
                                        <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn payment-btn' : 'btn wizard-btn payment-btn'}>
                                            <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                                        </button>
                                        {skiPayOpt &&
                                        <button type="button" onClick={() => this.skiPay()} className="btn wizard-btn payment-btn skip ">Dealer Remit</button>
                                        }
                                        
                                    </div>

                                </Formsy>
                                <div className="txtright">* Field is required</div>
                            </div>

                        </div>
                    </div>
                    {global.dealertoken?'':
                    <div className="vehicle-bottom-line bottom-line">
                    <img src={bottomLine} alt="line" />
                    </div> 
                    }
                </section>
            </Layout>
        );
    }
}


const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
        step2Data: state.register.step2,
        step2response: state.register.step2response,
        step5response: state.register.step4response,
        step5: state.register.step5,
        step3Data: state.register.step3response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
        saveFifthStep: (formData) => dispatch(actionCreators.saveFifthStep(formData)),
        saveFifthStepResponse: (response) => dispatch(actionCreators.saveFifthStepResponse(response)),
        saveFourthStep: (formData) => dispatch(actionCreators.saveFourthStep(formData)),
        saveSecondStep: (formData) => dispatch(actionCreators.saveSecondStep(formData)),
        saveThirdStep: (formData) => dispatch(actionCreators.saveThirdStep(formData)),
        saveThirdStepResponse: (response) => dispatch(actionCreators.saveThirdStepResponse(response)),
        saveFirstStep: (formData) => dispatch(actionCreators.saveFirstStep(formData)),
        saveSecondStepResponse: (response) => dispatch(actionCreators.saveSecondStepResponse(response)),
        clearAllSteps:() => dispatch(actionCreators.ClearAllSteps())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurePaymentPage);